


























































































import {isStarted} from "node_modules/@types/nprogress";
import {Component, Vue} from "vue-property-decorator";

@Component({
  name: "chapterSystem"
})
export default class chapterSystem extends Vue {
  private rightLoading = false;
  private options: any = [{}, {}, {}];
  private subjectArr: any = []
  private id = this.generateId();
  private bookData: any = [];
  private radio = {};
  private chapterData = [];
  private dialogFormVisible = false;
  private chapterName = '';
  private formLabelWidth = '120px';
  private currentNode: { [key: string]: any } = {};
  private currentId = '';
  private dialogTitle = '新增章节';
  private defaultExpandNodes: any = []
  private state: string = ''
  private textInputVal: string = ''
  private inputVisible: boolean = false

  /**
   * @fuc blurText 失去焦点
   * @params name: 当前输入框值  id存在就是编辑 否则就是新增
   * @params versionId 章节ID   versionName 章节名称  subject 学科id
   */
  private async inputBluerText(item: any, name?: any, idx?: number, val?: any) {
    if (!name) return
    this.bluerFunc({
      id: val.id,
      name: name,
      subject: this.subjectArr[1],
      versionId: item.id,
      versionName: item.name
    }, idx)
    this.bookData = this.bookData.map((i: any) => {
      if (i.bookVersions) {
        i.bookVersions = i.bookVersions.map((k: any) => {
          k.isInput = false
          return k
        })
      }
      return i
    })
  }

  /**
   * @inputBluerText和@blurText 公用的方法
   *
   * @func 公用的方法 - -增加
   */
  private async bluerFunc(params: any, idx?: number) {
    const res: any = await this.axios.post('/tiku/bookVersion/addBookVersion', params, {headers: {'Content-Type': 'application/json'}});
    if (res.result) {
      this.getBookData(this.subjectArr[1], idx)
      this.inputVisible = false;
      this.textInputVal = '';
    }
  }

  /**
   * @fuc blurText 失去焦点
   * @idx 记住当前的索引值
   */
  private async blurText(item: any, idx: number) {
    this.inputVisible = false;
    if (this.textInputVal == '') return;
    this.bluerFunc( {
      name: this.textInputVal,
      subject: this.subjectArr[1],
      versionId: item.id,
      versionName: item.name
    }, idx)
  }

  /**
   * @func 点击切换按钮并获取焦点
   */
  private showInput(subject, idx?: number, index?: number) {
    if (subject === 'every') {
      this.bookData = this.bookData.map((i: any, idxs: number) => {
        if (i.bookVersions && idxs === idx) {
          i.bookVersions = i.bookVersions.map((k: any, indexs: number) => {
            if (indexs === index) k.isInput = !k.isInput
            else k.isInput = false
            return k
          })
        }
        return i
      })
      this.$nextTick(() => {
        this.$refs.everyRef && this.$refs.everyRef[0].focus()
      })
    } else {
      this.inputVisible = true;
      this.$nextTick(() => {
        // (this.$refs.saveInput as any).$refs.input.focus();
        this.$refs.saveInput && this.$refs.saveInput[0].focus()
      })
    }
  }

  /**
   * @move
   * @item 当前移动的值
   * @index
   * @list 循环的list
   * @idx 为了展开当前操作的
   */
  private async move(moveTip: string, item: any, index: number, list: any[], idx: number) {
    let _list: any = null
    let _params: any[] = []
    console.log(list, 'list', index)
    if (moveTip === 'up') _params = [{id: item.id, sortIndex: list[index - 1].sortIndex}, {
      id: list[index - 1].id,
      sortIndex: item.sortIndex
    }]
    if (moveTip === 'down') _params = [{id: item.id, sortIndex: list[index + 1].sortIndex}, {
      id: list[index + 1].id,
      sortIndex: item.sortIndex
    }]

    const res: any = await this.axios.post('/tiku/bookVersion/updateSort', _params, {headers: {'Content-Type': 'application/json'}})
    if (res.result) this.getBookData(this.subjectArr[1], idx)
  }

  private async deleteText(ids: string, idx: number) {
    const res: any = await this.axios.post('/tiku/bookVersion/addBookVersion', {
      id: ids,
      deleted: 1
    }, {headers: {'Content-Type': 'application/json'}})
    if (res.result) this.getBookData(this.subjectArr[1], idx)
  }

  /**
   * @func input防抖
   */
  private async querySearchAsync(queryString: string) {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      this.debounce(queryString)
    }, 300)
  }

  private async debounce(queryString: string) {
    let _params = {
      subject: this.subjectArr[1],
      name: queryString
    }
    const res: any = await this.axios.post('/tiku/bookVersion/queryLikeBookVersionBySubjectCode', _params, {headers: {'Content-Type': 'application/json'}})
    if (res.result) {
      this.bookData = res.json;
      this.bookData && this.bookData[0] && this.bookData[0].bookVersions && this.bookChange(this.bookData[0].bookVersions[0])
    }
  }

  private nodeExpend(nodes, node, self) {
    let id: any = null
    id = node.data.id
    this.defaultExpandNodes.push(id)
  }

  private nodeCollapse(nodes, node, self) {
    let id: any = null
    id = node.data.id
    this.defaultExpandNodes = this.defaultExpandNodes.filter(i => i !== id)
  }

  generateId() {
    return Math.floor(Math.random() * 10000);
  };

  append(data) {
    this.dialogTitle = '新增章节';
    this.dialogFormVisible = true;
    this.currentNode = data
  };

  edit(data) {
    this.dialogFormVisible = true;
    this.dialogTitle = '编辑章节';
    this.currentNode = data
    this.chapterName = data.name;
    this.currentId = data.id;
  };

  private async remove(node, {id}) {
    let confirm = await this.$confirm('此操作将永久删除该章节, 是否继续?', '提示', {type: 'warning'});
    if (confirm) {
      const res: any = await this.axios.post('/tiku/bookChapter/deleteById', {id});
      res.result && this.$message.success('删除章节成功~！') && this.bookChange(this.radio);
    }
    ;
  }

  addOrUpdateChapter() {
    this.saveChapterInfo(this.currentId, this.chapterName, this.currentNode.id, this.currentNode.bookVersionId);
  }

  private saveChapterInfo(id = '', name, parentId, bookVersionId) {
    this.axios.post('/tiku/bookChapter/saveOrUpdate', {id, name, parentId, bookVersionId}).then((res: any) => {
      this.dialogFormVisible = false;
      this.bookChange(this.radio)
    });
  }

  private async getSubject() {
    const res: any = await this.axios.post('/system/dictionary/querySubject');
    if (res.result) {
      Object.keys(res.json).forEach((key, index) => {
        let subjectParName = ''
        switch (key) {
          case 'high':
            subjectParName = '高中'
            break;
          case 'middle':
            subjectParName = '初中'
            break;
          case 'primary':
            subjectParName = '小学'
            break;
          default:
            subjectParName = '小学'
            break;
        }
        this.$set(this.options[index], 'value', key)
        this.$set(this.options[index], 'label', subjectParName)
        this.$set(this.options[index], 'children', res.json[key])
      });
      this.options.forEach((item: any, index: number) => {
        item.children.forEach((key: any) => {
          this.$set(key, 'value', key.code)
          this.$set(key, 'label', key.name)
        });
      });
      this.options = this.options.filter(i => i.children && i.children.length !== 0)
      this.subjectArr[0] = this.options[0].value;
      this.subjectArr[1] = this.options[0].children[0].value;
      this.getBookData(this.subjectArr[1]);
    }
  };

  private subjectChange() {
    this.getBookData(this.subjectArr[1]);
  }

  private bookChange(book) {
    this.radio = book;
    this.rightLoading = true;
    this.axios.post('/tiku/bookChapter/queryTreeByBookVersion', {bookId: book.id}).then((res: any) => {
      this.chapterData = res.json;
      this.rightLoading = false
    })
  }

  private openItem(item: any, idx: number) {
    item.show = !item.show
    this.inputVisible = false
  }

  /**
   * @index 当前增加的索引
   */
  private async getBookData(subjectCode, index?: number) {
    const cascaderREF: any = this.$refs['cascader'];
    const res: any = await this.axios.post('/tiku/bookVersion/queryBookVersionBySubjectCode', {subjectCode});
    res.json.forEach((item: any, idx) => {
      item.show = (index == idx);
      item.subjectName = cascaderREF.presentText.split('/')[1];
    });
    this.bookData = res.json;
    this.radio = this.bookData[0].bookVersions ? this.bookData[0].bookVersions[0] : {};
    this.bookChange(this.radio)
  }

  private down(data) {
    this.moveUpOrDown(data.id, -1);
  };

  private up(data) {
    this.moveUpOrDown(data.id, 1);
  };

  private moveUpOrDown(bookChapterId, moveType) {
    this.axios.post('/tiku/bookChapter/moveUpOrDownBookChapter', {bookChapterId, moveType}).then((res: any) => {
      this.bookChange(this.radio)
    })
  };

  created() {
    this.getSubject();
  }

  mounted() {
    let dom: any = document.getElementsByClassName('container')[0];
    dom.style.background = '#F4F5F9';
    dom.style.boxShadow = 'none';
    dom.style.border = 'none';
    dom.style.height = "100%"
  };

  destroyed() {
    let dom: any = document.getElementsByClassName('container')[0];
    dom.style.background = '';
    dom.style.boxShadow = '';
    dom.style.border = '';
    dom.style.height = ""
  }
}
